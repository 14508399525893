//NEXTJS
import Head from "next/head";
import { useState } from "react";

//CSS
import "../styles/styles.global.scss";

//COMPONENTS
import { Alert, AlertColor, Snackbar } from "@mui/material";

//TYPES
import type { AppProps } from "next/app";
import type { AlertProps } from "../lib/sharedTypes";
declare global {
  interface Window {
    setSnackbar: React.Dispatch<React.SetStateAction<AlertProps>>;
  }
}

function MyApp({ Component, pageProps }: AppProps) {
  const [snackbar, setSnackbar] = useState<AlertProps>(null);

  if (typeof window !== "undefined") {
    // @ts-ignore
    window.setSnackbar = setSnackbar;
  }

  return (
    <>
      <Head>
        <meta charSet="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>Dashboard - BallisticArts</title>
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#3e3e3e" />
        <meta name="msapplication-TileColor" content="#3e3e3e" />
        <meta name="theme-color" content="#3e3e3e" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <Component {...pageProps} />
      <Snackbar
        open={snackbar !== null && snackbar.message !== ""}
        autoHideDuration={6000}
        onClose={() => setSnackbar(null)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert severity={(snackbar?.severity as AlertColor) || "info"}>{snackbar?.message}</Alert>
      </Snackbar>
    </>
  );
}

export default MyApp;
